// boilerplate generated 2024-05-19 08:42:57
import Vue from 'vue';

import Vuex from 'vuex';
import navigation from '@/vuex/navigation.js';
import checkpoints from '@/vuex/checkpoints.js';
import context from '@/vuex/context.js';
import database from '@/vuex/database.js';
import datapoints from '@/vuex/datapoints.js';
import actions from '@/vuex/actions.js';
import timers from '@/vuex/timers.js';
import server from '@/vuex/server.js';
import socket from '@/vuex/socket.js';
import counter from '@/vuex/counter.mjs';

Vue.use(Vuex)
export default new Vuex.Store({
strict: true,
  modules: {
	navigation,
	checkpoints,
	context,
	database,
	datapoints,
	actions,
	timers,
	server,
	socket,
	counter,
	}
})
